<template>
    <FeCard
        v-if="bannersByType.length"
        :padding="FeCardConfig.PaddingSizes.paddingS"
        :theme="FeCardConfig.themes.Dark"
        class="promo-slider__card gap-row-s"
    >
        <div class="promo-slider__top">
            <p class="h1">
                {{ titleCard }}
            </p>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondaryMedium"
                class="promo-slider__top-btn"
                @click="handleClickSeeAll"
            >
                {{ titleButton }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondarySmall"
                class="promo-slider__top-btn"
                @click="handleClickSeeAll"
            >
                {{ titleButton }}
            </FeButton>
        </div>
        <PromoSlider
            :category-banners="categoryBanners"
            @click-banner="(propItem, index) => emit('clickBanner', propItem, index)"
            @view-banner="handleView"
        />

        <div class="promo-slider__bottom">
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondaryMedium"
                full-width-mobile
                @click="handleClickSeeAll"
            >
                {{ titleButton }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondarySmall"
                full-width-mobile
                @click="handleClickSeeAll"
            >
                {{ titleButton }}
            </FeButton>
        </div>
    </FeCard>
</template>

<script lang="ts" setup>
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";
import { computed, defineEmits } from "vue";
import { useRouter } from "vue-router";

import PromoSlider from "@components/PromoSlider/PromoSlider.vue";
import FeCard, * as FeCardConfig from "@ui/FeCard/FeCard.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";

import { BANNERS_CATEGORIES } from "@config/banners";
import { routeNames } from "@router/routeNames";

import { useI18n } from "vue-i18n";
import type { TranslateResult } from "vue-i18n";
import { labelToString } from "@helpers/promoHelpers";
import { useBannerStore } from "@store/banners";

const { t } = useI18n();
const $router = useRouter();

const emit = defineEmits([ "clickBanner" ]);

const props = defineProps({
    categoryBanners: {
        type: String,
        required: true,
        validator: (prop: string) => [ BANNERS_CATEGORIES.PROMO, BANNERS_CATEGORIES.TOURNAMENTS ].includes(prop),
    },
});

const bannerStore = useBannerStore();

const titleCard = computed<TranslateResult | undefined>(() => {
    if (props.categoryBanners === BANNERS_CATEGORIES.PROMO) {
        return t("BANNERS_SLIDER.PROMO.TITLE");
    }
    if (props.categoryBanners === BANNERS_CATEGORIES.TOURNAMENTS) {
        return t("BANNERS_SLIDER.TOURNAMENTS.TITLE");
    }
});

const titleButton = computed<TranslateResult | undefined>(() => {
    if (props.categoryBanners === BANNERS_CATEGORIES.PROMO) {
        return t("BANNERS_SLIDER.PROMO.BUTTON");
    }
    if (props.categoryBanners === BANNERS_CATEGORIES.TOURNAMENTS) {
        return t("BANNERS_SLIDER.TOURNAMENTS.BUTTON");
    }
});

const bannersByType = computed<[]>(() => {
    return bannerStore.getBannersData.filter((item) => {
        return item.categories.includes(props.categoryBanners);
    });
});

function handleClickSeeAll() {
    if (props.categoryBanners === BANNERS_CATEGORIES.PROMO) {
        return $router.push("/promotions");
    }
    if (props.categoryBanners === BANNERS_CATEGORIES.TOURNAMENTS) {
        return $router.push({ name: routeNames.tournamentsAll });
    }
}

function handleView(banner, index) {
    if ($router.currentRoute.value.name === routeNames.main) {
        // if no index passed or banner is already viewed
        if (bannerStore.viewedGTMBanners.some((item) => item.location_id === props.categoryBanners &&
        item.creative_slot === index + 1)) {
            return;
        }

        const viewedBanner = {
            promotion_id: banner.id,
            promotion_name: labelToString(banner.labels),
            location_id: props.categoryBanners,
            creative_slot: index + 1,
        };

        bannerStore.setViewedGTMBanners(viewedBanner);
    }
}
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.promo-slider {
    &__card {
        width: 100%;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-btn {
            display: none;

            @include media(M) {
                display: block;
            }
        }
    }

    &__bottom {
        @include media(M) {
            display: none;
        }
    }
}
</style>
