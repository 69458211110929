<template>
    <div class="crypto-info__wrap">
        <div class="crypto-info">
            <p v-t="'BENEFIT_CRYPTO_PAGE.CRYPTO_INFORMER.TITLE'" class="h2" />
            <p
                v-t="'BENEFIT_CRYPTO_PAGE.CRYPTO_INFORMER.DESCRIPTION'"
                class="crypto-info__desc"
            />
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondaryMedium"
                full-width-mobile
                @click="handleClick"
            >
                {{ $t("BENEFIT_CRYPTO_PAGE.CRYPTO_INFORMER.BUTTON") }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondarySmall"
                full-width-mobile
                @click="handleClick"
            >
                {{ $t("BENEFIT_CRYPTO_PAGE.CRYPTO_INFORMER.BUTTON") }}
            </FeButton>
        </div>
        <div class="crypto-info__logos-wrap">
            <FeImage
                v-for="item in cryptoCoins"
                :key="item"
                :src="item"
                class="crypto-info__logo-coin"
            />
        </div>
    </div>
</template>

<script>
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import { routeNames } from "@router/routeNames";
import FeButtonNew from "@ui/New/FeButton.vue";

import eth from "./img/eth.svg";
import doge from "./img/doge.svg";
import btc from "./img/btc.svg";
import bch from "./img/bch.svg";
import usdt from "./img/usdt.svg";
import ltc from "./img/ltc.svg";
import featureFlags from "@theme/configs/featureFlags";
import { themes } from "@ui/New/ButtonConfig";

export default {
    name: "CryptoInformer",

    components: {
        FeButton,
        FeImage,
        FeButtonNew,
    },

    data() {
        return {
            FeButtonConfig,

            cryptoCoins: [
                eth,
                doge,
                btc,
                bch,
                usdt,
                ltc,
            ],
        };
    },
    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
    },

    methods: {
        handleClick() {
            this.$router.push({ name: routeNames.benefitCrypto });
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.crypto-info {
    order: 1;

    @include media(M) {
        order: 0;
    }

    h2 {
        margin-bottom: .5rem;

        @include media(M) {
            margin-bottom: 0;
        }
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        padding: 1.5rem 2rem;
        align-items: center;
        text-align: center;

        @include media(M) {
            justify-content: space-between;
            flex-direction: row;
            align-items: unset;
            padding: 0;
            text-align: start;
        }
    }

    &__desc {
        margin-bottom: 1rem;

        @include media(M) {
            margin-bottom: 1.5rem;
        }
    }

    &__logos-wrap {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        order: 0;
        margin-bottom: 1rem;

        @include media(M) {
            order: 1;
            margin-bottom: 0;
        }
    }

    &__logo-coin {
        width: 2rem;
        height: 2rem;
        margin-left: .5rem;

        @include media(M) {
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 1rem;
        }
    }
}
</style>
