<template>
    <GameRow
        v-if="favoriteGames.length"
        class="grid-full-width"
        :games="favoriteGames"
        :url="url"
        :title="$t('FAVORITES.TITLE_GAMES_ROW')"
    />
</template>

<script>

import GameRow from "@modules/GameHall/components/GameRow/GameRow.vue";
import { routeNames } from "@router/routeNames";
import { mapState } from "pinia";
import { useGamesFavorite } from "@store/games/gamesFavorite";

export default {
    name: "FavoriteGamesRow",

    components: {
        GameRow,
    },

    computed: {
        ...mapState(useGamesFavorite, {
            favoriteGames: "getGamesFavoriteFullData",
        }),
        url() {
            return {
                name: routeNames.favoriteGames,
            };
        },
        slug() {
            return "";
        },
    },
};
</script>

<style scoped lang="scss"></style>
