<template>
    <FeCard :theme="FeCardConfig.themes.Dark" class="jackpot-card">
        <div class="jackpot-card__top">
            <div class="jackpot-card__info gap-xs align-items-center">
                <FeImage
                    :src="jackpotTotal"
                    class="jackpot-card__icon"
                />
                <div>
                    <p class="jackpot-card__title">
                        {{ $t("JACKPOT_CARD.TITLE", {currency: userCurrency}) }}
                    </p>
                    <p class="jackpot-card__title--black">
                        {{ totalJackpot }}
                    </p>
                </div>
            </div>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondaryMedium"
                class="jackpot-card__btn-top"
                @click="goToJackpot"
            >
                {{ $t("JACKPOT_CARD.BUTTON") }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondarySmall"
                class="jackpot-card__btn-top"
                @click="goToJackpot"
            >
                {{ $t("JACKPOT_CARD.BUTTON") }}
            </FeButton>
        </div>

        <div
            v-if="showLargeItem && isMobile"
            class="jackpot-card__large-container"
        >
            <GameItem
                :game="firstGame"
                :is-large="true"
                @open-demo="navigateDemo(firstGame)"
                @open-game="navigateGame(firstGame)"
            />
        </div>

        <GameRow
            :category="SlugCategoriesGames.SLUG_CATEGORY_JACKPOT"
            :large-item-index="showLargeItem && !isMobile ? 0 : null"
            title-hide
            url-hide
            class="jackpot-card__game-row grid-full-width"
        />

        <div class="jackpot-card__btn-bottom">
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.SecondaryMedium"
                full-width-mobile
                @click="goToJackpot"
            >
                {{ $t("JACKPOT_CARD.BUTTON") }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="FeButtonConfig.themes.SecondarySmall"
                full-width-mobile
                @click="goToJackpot"
            >
                {{ $t("JACKPOT_CARD.BUTTON") }}
            </FeButton>
        </div>
    </FeCard>
</template>

<script>
import FeCard, * as FeCardConfig from "@ui/FeCard/FeCard.vue";
import GameRow from "@modules/GameHall/components/GameRow/GameRow.vue";
import GameItem from "@modules/GameHall/components/GameItem/GameItem.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";

import jackpotTotal from "./images/jackpot-total.svg";

import { routeNames } from "@router/routeNames";
import gameNavigateMixin from "@modules/GameHall/mixins/gameNavigateMixin.js";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";
import { mapState } from "pinia";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGamesCategory } from "@store/games/gamesCategory";
import { useUserInfo } from "@store/user/userInfo";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { themes } from "@ui/New/ButtonConfig";
import { useRootStore } from "@store/root";

export default {
    name: "JackpotCard",

    components: {
        FeCard,
        GameRow,
        FeImage,
        FeButton,
        GameItem,
        FeButtonNew,
    },

    mixins: [ gameNavigateMixin ],

    props: {
        showLargeItem: {
            type: Boolean,
        },
    },

    data() {
        return {
            routeNames,
            FeCardConfig,
            FeButtonConfig,
            jackpotTotal,
            SlugCategoriesGames,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        featureFlags() {
            return featureFlags;
        },
        ...mapState(useRootStore, {
            isMobile: "isMobile",
        }),

        ...mapState(useGamesCategory, {
            getCategoryGames: "getCollection",
        }),

        ...mapState(useGamesCommon, {
            totalJackpot: "getJackpotTotalByCurrency",
        }),

        ...mapState(useUserInfo, {
            userCurrency: "getUserCurrency",
        }),

        firstGame() {
            return this.getCategoryGames(SlugCategoriesGames.SLUG_CATEGORY_JACKPOT, 1)[0];
        },
    },

    methods: {
        goToJackpot() {
            this.$router.push({
                name: routeNames.categoryGames,
                params: { slug: SlugCategoriesGames.SLUG_CATEGORY_JACKPOT },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.jackpot-card {
    padding: 1rem 0;

    &__info {
        display: grid;
        grid-auto-flow: row;
        margin: 0 auto;
        justify-items: center;
        text-align: center;

        @include media(M) {
            grid-auto-flow: column;
            margin: unset;
            text-align: start;
        }
    }

    &__icon {
        width: 4rem;
        height: 4rem;
    }

    &__btn-top {
        display: none;

        @include media(M) {
            display: block;
        }
    }

    &__btn-bottom {
        margin-top: 1rem;
        padding: 0 1rem;

        @include media(M) {
            display: none;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 var(--carousel-gap);
    }

    &__title {
        @include font-size--Caption1;
        color: var(--color-text-body);

        &--black {
            @include font-size--Header1($font-weight--black);
        }
    }

    :deep(.carousel-scroll--transparent-border .carousel-scroll__item) {
        padding-left: var(--carousel-gap);
    }

    &__game-row {
        --carousel-item-width: auto;
        --carousel-item--width--flex: 120px;

        @include media(M) {
            --carousel-item--width--flex: 200px;
        }

        :deep(.carousel-scroll--transparent-border .carousel-scroll__item) {
            padding-left: 0;

            &:first-child {
                padding-left: var(--carousel-gap);
            }
        }

        :deep(.carousel-scroll__item) {
            padding: 0;
            width: var(--carousel-item--width--flex);

            .carousel-scroll__item--large {
                width: calc(#{var(--carousel-item--width--flex)} * 1.4);
            }
        }
    }

    &__large-container {
        padding: 1rem 1rem 0;
    }
}

</style>
