<template>
    <div
        class="jackpots-block grid-full-width"
    >
        <div class="jackpots-block__wrapper">
            <div class="gap-row-s">
                <JackpotRowItem
                    v-for="item in jackpotsActiveList"
                    :key="item.id"
                    :jackpot-data="item"
                />
            </div>
            <JackpotLeaderBoard
                :amount-winners="3"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useJackpots } from "@store/jackpots";
import JackpotRowItem from "./JackpotRowItem.vue";
import JackpotLeaderBoard from "@components/JackpotsCustom/JackpotRow/JackpotLeaderBoard/JackpotLeaderBoard.vue";

const jackpotsStore = useJackpots();
const { jackpotsActiveList } = storeToRefs(jackpotsStore);
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.jackpots-block {
    padding: 0 1rem;
    @include media(M) {
        padding: 0;
    }

    &__wrapper {
        background: var(--color-secondary-gradient);
        border-radius: var(--border-radius-s);

        :deep(.jackpot-leaderboard) {
            background: var(--color-tertiary-5);
            border-radius: 0;
            @include media(M) {
                justify-content: center;
            }

            .jackpot-leaderboard__title {
                display: none;
            }
        }

        :deep(.jackpot-leaderboard--with-bg) {
            &::before {
                content: url("../img/jackpots-bg-left.png");
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &::after {
                content: url("../img/jackpots-bg-right.png");
                position: absolute;
                top: 0;
                right: 0;
                z-index: 0;
            }
        }

        :deep(.jackpot-leaderboard__wrapper--main) {
            background: transparent;
            border-radius: 0;
            @include media(M) {
                grid-template-columns: repeat(2, minmax(0, 21rem));
                padding: 1.5rem 0;
            }
        }

        :deep(.jackpot-leaderboard-block) {
            padding: 0;
            border-radius: var(--border-radius-xs);
            background: none;

            .jackpot-leaderboard-block__title {
                @include font-size--h2;
                text-align: left;
                text-transform: none;
                @include media(XS) {
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                }
            }
        }

        :deep(.fe-tabs__header) {
            background: var(--color-tertiary-5);

            .fe-tab {
                &--active {
                    background: var(--color-tertiary-3);
                }
            }
        }

        :deep(.jackpot-leaderboard-item) {
            background: var(--color-tertiary-6);
        }
    }
}
</style>
