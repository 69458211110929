<template>
    <div class="jackpot-item gap-row-xs padding-s">
        <div class="jackpot-item__top">
            <div class="jackpot-item__title-block gap-column-xs">
                <p class="h1">
                    {{ nameJackpot }}
                </p>
                <FeImage :src="jackpotIcon" class="jackpot-item__icon"/>
            </div>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                class="jackpot-item__button-games"
                @click="goToGames"
            >
                {{
                    $t("JACKPOTS.ITEM.BUTTON", { name: nameJackpot })
                }}
            </FeButtonNew>
            <FeButton
                v-else
                class="jackpot-item__button-games"
                @click="goToGames"
            >
                {{
                    $t("JACKPOTS.ITEM.BUTTON", { name: nameJackpot })
                }}
            </FeButton>
        </div>
        <div class="gap-row-s">
            <i18n-t :keypath="`JACKPOTS.ITEM.${ jackpotData.identifier }.DESC`" tag="p">
                <template #link>
                    <router-link
                        :to="`/${jackpotData.identifier}`"
                        class="jackpot-item__link"
                    >
                        {{ $t(`JACKPOTS.ITEM.${jackpotData.identifier}.LINK`) }}
                    </router-link>
                </template>
            </i18n-t>

            <div class="jackpot-item__levels-list">
                <JackpotLevelItem
                    v-for="jackpotLevel in jackpotData.levels"
                    :key="jackpotLevel.id"
                    :jackpot-currency="jackpotData.currency"
                    :level-data="jackpotLevel"
                />
            </div>
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                class="jackpot-item__button-games--bottom"
                @click="goToGames"
            >
                {{
                    $t("JACKPOTS.ITEM.BUTTON", { name: nameJackpot })
                }}
            </FeButtonNew>
            <FeButton
                v-else
                class="jackpot-item__button-games--bottom"
                @click="goToGames"
            >
                {{
                    $t("JACKPOTS.ITEM.BUTTON", { name: nameJackpot })
                }}
            </FeButton>
        </div>

        <router-link
            :to="`/${jackpotData.identifier}`"
            class="jackpot-item__link text--align-center jackpot-item__link--bottom"
        >
            {{ $t(`JACKPOTS.ITEM.${jackpotData.identifier}.LINK`) }}
        </router-link>
    </div>
</template>

<script lang="ts" setup>
import type { IJackpotItem } from "@api/DTO/jackpot";
import featureFlags from "@theme/configs/featureFlags";
import FeButtonNew from "@ui/New/FeButton.vue";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { routeNames } from "@router/routeNames";
import { useI18n } from "vue-i18n";
import FeButton from "@ui/FeButton/FeButton.vue";
import JackpotLevelItem from "../JackpotLevelItem.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import jackpotIcon from "../img/mystery-jackpots.svg";
import { getTitleFromIdentifier } from "@helpers/getTitleFromIdentifier";

interface Props {
    jackpotData: IJackpotItem;
}

const router = useRouter();
const props = defineProps<Props>();
const { t: $t } = useI18n();

const nameJackpot = computed<string>(() => {
    return getTitleFromIdentifier(props.jackpotData.identifier);
});

function goToGames() {
    router.push({
        name: routeNames.categoryGames,
        params: { slug: props.jackpotData.identifier },
    });
}

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.jackpot-item {

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__button-games {
        display: none;

        @include media(M) {
            display: block;
        }

        &--bottom {
            margin: 0 auto;
            @include media(M) {
                display: none;
            }
        }
    }

    &__link {
        color: var(--color-primary-1);
        display: none;

        @include media(M) {
            display: inline;
        }

        &--bottom {
            display: inline;

            @include media(M) {
                display: none;
            }
        }
    }

    &__levels-list {
        display: grid;

        gap: 1rem;

        @include media(S) {
            grid-auto-flow: column;
            grid-template-columns: repeat(3, 1fr);
        }

        @include media(M) {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
        }

        @include media(XM) {
            grid-auto-flow: column;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__title-block {
        align-items: center;
    }

    &__icon {
        width: 1.5rem;
        height: 1.5rem;

        @include media(M) {
            width: 2rem;
            height: 2rem;
        }

    }
}
</style>
