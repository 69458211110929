<template>
    <div
        v-if="recentGames && recentGames.length > 0"
        class="last-games"
    >
        <div
            class="game-row__cover mobile-layout-width"
        >
            <p data-test="game-row__cover-title" class="h2">
                {{ $t("GAME_HALL.LAST_GAME") }}
            </p>
        </div>
        <CarouselScroll
            class="last-games__carousel"
            :transparent-border="true"
        >
            <Substrate
                v-for="game in recentGames"
                :key="game.id"
                class="last-games__img"
            >
                <GamePreviewThumb
                    :size="GamePreviewThumbConfig.sizeList.small"
                    :game="game"
                    :lazy="false"
                />
            </Substrate>
        </CarouselScroll>
    </div>
</template>

<script setup>
import Substrate from "@components/Substrate/Substrate.vue";

import CarouselScroll from "@ui/CarouselScroll/CarouselScroll.vue";
import GamePreviewThumb, * as GamePreviewThumbConfig from "@components/GamePreviewThumb/GamePreviewThumb.vue";
import { storeToRefs } from "pinia";
import { useGamesCommon } from "@store/games/gamesStore";

const { getRecentGames: recentGames } = storeToRefs(useGamesCommon());

</script>
<style lang="scss" src="./style.scss"></style>
