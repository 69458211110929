<template>
    <div ref="gameRow">
        <template v-if="isLogged">
            <LastGames class="grid-full-width"/>
        </template>

        <GiftsRow class="grid-full-width"/>
        <FavoriteGamesRow v-if="isLogged"/>

        <GameRow
            v-if="enableSpecialGameRow"
            :category="SlugCategoriesGames.SLUG_CATEGORY_SPECIAL"
            class="grid-full-width"
        />

        <GameRow
            :category="SlugCategoriesGames.SLUG_CATEGORY_TOP"
            class="grid-full-width"
        />
        <JackpotRow
            v-if="featureFlags.enableMysticJackpots"
        />
        <GameRow
            v-for="jackpot in jackpotsActiveList"
            :key="jackpot.id"
            :category="jackpot.identifier"
            class="grid-full-width"
        />

        <GameRow
            :category="SlugCategoriesGames.SLUG_CATEGORY_NEW"
            class="grid-full-width"
        />

        <div class="game-hall__slider-container grid-full-width">
            <PromoSliderWrapper
                :category-banners="BANNERS_CATEGORIES.PROMO"
                @click-banner="handleClickBannerPromo"
            />
        </div>

        <GameRow
            v-for="(item, index) in rowsBottom"
            :key="'rowsBottom'+index"
            :category="item.slug"
            class="grid-full-width"
        />

        <CryptoInformer v-if="!isLicenseEE" class="grid-full-width"/>
        <GameRow
            v-if="!isLicenseEE"
            :category="SlugCategoriesGames.SLUG_CATEGORY_BTC_GAMES"
            class="grid-full-width"
        />

        <div class="jackpot-container grid-full-width">
            <JackpotCard/>
        </div>

        <div class="game-hall__slider-container grid-full-width">
            <PromoSliderWrapper
                :category-banners="BANNERS_CATEGORIES.TOURNAMENTS"
                @click-banner="handleClickBannerPromo"
            />
        </div>

        <GameRow
            :category="SlugCategoriesGames.SLUG_CATEGORY_WINN_WEEK"
            class="grid-full-width"
        />
        <GameRow
            :category="SlugCategoriesGames.SLUG_CATEGORY_WIN_WEEK_TOUR"
            class="grid-full-width"
        />

        <div class="game-hall__bottom grid-full-width">
            <FeButtonNew
                v-if="featureFlags.enableNewUIComponents"
                :theme="themes.PrimaryLarge"
                @click="$router.push(allGamesButtonUrl)"
            >
                {{ $t("GAME_HALL_BOTTOM.BUTTON") }}
            </FeButtonNew>
            <FeButton
                v-else
                :theme="ButtonConfig"
                @click="$router.push(allGamesButtonUrl)"
            >
                {{ $t("GAME_HALL_BOTTOM.BUTTON") }}
            </FeButton>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { routeNames } from "@router/routeNames";

import GameRow from "@modules/GameHall/components/GameRow/GameRow.vue";
import JackpotCard from "@modules/GameHall/components/JackpotCard/JackpotCard.vue";
import GiftsRow from "@modules/Gifts/GiftsRow/GiftsRow.vue";
import LastGames from "@modules/GameHall/components/LastGames/LastGames.vue";
import FeButton, * as FeButtonConfig from "@ui/FeButton/FeButton.vue";
import featureFlags from "@theme/configs/featureFlags";
import FavoriteGamesRow from "@modules/FavoriteGames/FavoriteGamesRow.vue";
import PromoSliderWrapper from "@components/PromoSlider/PromoSliderWrapper.vue";
import CryptoInformer from "@components/CryptoInformer/CryptoInformer.vue";
import JackpotRow from "@components/JackpotsCustom/JackpotRow/JackpotRow.vue";

import { BANNERS_CATEGORIES } from "@config/banners.js";
import { SlugCategoriesGames } from "@theme/configs/categoryesGames";
import { useJackpots } from "@store/jackpots";
import { useLicense } from "@helpers/license";
import { useGamesCommon } from "@store/games/gamesStore";
import { useGamesCategory } from "@store/games/gamesCategory";
import { getAffiliate } from "@helpers/getAffiliate";
import { useUserInfo } from "@store/user/userInfo";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";

export default {
    name: "LobbyPage",

    components: {
        GameRow,
        GiftsRow,
        FeButton,
        LastGames,
        FavoriteGamesRow,
        JackpotCard,
        PromoSliderWrapper,
        CryptoInformer,
        JackpotRow,
        FeButtonNew,
    },

    setup() {
        const { isLicenseEE } = useLicense();

        return {
            isLicenseEE,
        };
    },

    data() {
        return {
            routeNames,
            ButtonConfig: FeButtonConfig.themes.PrimaryMedium,
            SlugCategoriesGames,
            rowsBottom: [
                { slug: SlugCategoriesGames.SLUG_CATEGORY_RECOMMENDED },
                { slug: SlugCategoriesGames.SLUG_CATEGORY_BONUS_WAGERING },

            ],
            BANNERS_CATEGORIES,
            featureFlags,
        };
    },

    computed: {
        themes() {
            return themes;
        },
        ...mapState(useUserInfo, {
            isLogged: "getIsLogged",
        }),

        ...mapState(useGamesCategory, {
            getCategoryGames: "getCollection",
        }),

        ...mapState(useGamesCommon, [ "gamesCategories" ]),

        ...mapState(useJackpots, [
            "jackpotsActiveList",
        ]),

        allGamesButtonUrl() {
            return { name: routeNames.categoryGames, params: { slug: SlugCategoriesGames.SLUG_CATEGORY_ALL } };
        },

        enableSpecialGameRow() {
            return this.gamesCategories.find((gameCategoryItem) => {
                return gameCategoryItem.id === SlugCategoriesGames.SLUG_CATEGORY_SPECIAL;
            });
        },
    },

    methods: {
        handleClickBannerPromo(banner, index) {
            const { id, labels, categories } = banner;

            const name = labels.map(({ title }) => title).join(" | ");

            this.$gtm.customTrackEvent({
                event: "select_promotion",
                Affiliate: getAffiliate(this.$router),
                ecommerce: {
                    items: [ {
                        promotion_id: id,
                        promotion_name: name,
                        location_id: index + 1,
                        creative_slot: categories[0],
                    } ],
                },
            });
        },
    },

};
</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.game-hall__slider-container,
.jackpot-container {
    padding: 0 1rem;

    @include media(M) {
        padding: 0;
    }
}
</style>
